.img-responsive { width: 100%; height: auto; border-radius: 5px;}
.welcome-div { margin-bottom: 20px;}
.app-well {max-width: 50%;margin-top:10px}
.app-canvas {
	/*https://www.cssmatic.com/gradient-generato*/
	background: rgba(240,92,34,1);
	background: -moz-linear-gradient(top, rgba(240,92,34,1) 0%, rgba(240,92,34,1) 51%, rgba(255,255,255,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(240,92,34,1)), color-stop(51%, rgba(240,92,34,1)), color-stop(100%, rgba(255,255,255,1)));
	background: -webkit-linear-gradient(top, rgba(240,92,34,1) 0%, rgba(240,92,34,1) 51%, rgba(255,255,255,1) 100%);
	background: -o-linear-gradient(top, rgba(240,92,34,1) 0%, rgba(240,92,34,1) 51%, rgba(255,255,255,1) 100%);
	background: -ms-linear-gradient(top, rgba(240,92,34,1) 0%, rgba(240,92,34,1) 51%, rgba(255,255,255,1) 100%);
	background: linear-gradient(to bottom, rgba(240,92,34,1) 0%, rgba(240,92,34,1) 51%, rgba(255,255,255,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f05c22', endColorstr='#ffffff', GradientType=0 );
	/*
	background: url(/images/background.jpg);
	background-size: cover;
	height: 100%;
	background-position: center;
	background-repeat: no-repeat;
	*/
}

.app-container {
	height: 100v;
	background: rgba(255,146,10,1);
	background: -moz-linear-gradient(top, rgba(255,146,10,1) 0%, rgba(255,255,255,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,146,10,1)), color-stop(100%, rgba(255,255,255,1)));
	background: -webkit-linear-gradient(top, rgba(255,146,10,1) 0%, rgba(255,255,255,1) 100%);
	background: -o-linear-gradient(top, rgba(255,146,10,1) 0%, rgba(255,255,255,1) 100%);
	background: -ms-linear-gradient(top, rgba(255,146,10,1) 0%, rgba(255,255,255,1) 100%);
	background: linear-gradient(to bottom, rgba(255,146,10,1) 0%, rgba(255,255,255,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff920a', endColorstr='#ffffff', GradientType=0 );
	/*
	background: rgb(132,227,255);
	background: -moz-linear-gradient(180deg, rgba(132,227,255,1) 0%, rgba(184,255,252,1) 40%, rgba(224,255,213,1) 100%);
	background: -webkit-linear-gradient(180deg, rgba(132,227,255,1) 0%, rgba(184,255,252,1) 40%, rgba(224,255,213,1) 100%);
	background: linear-gradient(180deg, rgba(132,227,255,1) 0%, rgba(184,255,252,1) 40%, rgba(224,255,213,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#84e3ff",endColorstr="#e0ffd5",GradientType=1);
	*/
}

.navbar {
	background: rgb(240, 90, 34);
	border: #ddd 0px solid;
	border-bottom:orange 1px solid;
}
.navbar-default .navbar-nav>li>a {
	color: #fff;
	font-size: 12px;
	font-weight: bold;
}

.icon-bar .glyphicon {color:#fff}

.navbar {
	margin-bottom:0px;
}
.navbar-default .navbar-nav  >li > a:hover {
	color: rgb(33, 29, 112);
}

.navbar-header > a {color:#fff}
.navbar-default .navbar-toggle .icon-bar {background-color: #fff}
.app-background {background-color: #fff}
.app-item {width: 100%;height: auto;margin-bottom: 5px;border-radius: 5px}
.app-item-thumb {max-height: 150px;margin-bottom: 5px;border-radius: 5px;margin-top:15px}
.app-href {color:#333}
.app-body-title { padding: 0px 10px 0px 10px }
.app-body-title h1, h2 {
	margin: 0px;
	padding: 10px 0px 10px 0px;
}

.app-item-col { margin-bottom: 20px; }
.app-item-col a {text-decoration: none;color: #333;}
.app-item-col a:hover {text-decoration: none;color:rgba(240,90,34);}
.app-item-cat {
	margin: 0px 0px 15px 0px;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 18px;
}
.app-item-div {
	border-radius: 5px;
	border: #eee 1px solid;
	padding: 5px;
	background-color: #fff;
	min-height: 270px;
}
.app-item-div h5 {
	font-size:13px;
}

.app-item-news {
	padding-left:10px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}
.app-item-news .news-container {
	/*background: rgb(240, 90, 34);*/
	background: #fff;
	padding:10px;
	border-radius: 5px;
	margin-bottom: 15px;
}
.app-item-news h5 {margin-bottom: 10px;}
.app-item-news h5 > a {
	color: #333;
}
.app-item-news span > a {
	color:#fff;
}
.app-item-news .btn {
	font-size:10px;
	padding:5px;
	background: rgb(240, 90, 34);
	border:none
}

.app-footer {
	margin-top: 20px;
	margin-bottom: 20px;
	background-color: rgba(255,255,255,0.9);
	border: #ddd 1px solid;
	border-radius: 5px;
	padding-top: 10px;
}
.app-footer a { color: #333 }
.app-footer a:hover {color: #F44336; text-decoration: none}

.app-body-container {
	background-color: rgba(255,255,255,0.8);
	border: #ddd 1px solid;
	border-radius: 5px;
	padding-top: 10px;
	min-height: 500px;
	margin-top: 20px;
}

.app-logo {max-width: 200px;height: auto;}
.app-logo-container {
	background-color: #fff;
	padding: 5px;
	text-align: center;
	border-radius: 5px;
	margin-bottom: 20px;
}
.app-img-container {
	background-color: none;
	padding: 15px;
	text-align: center;
	border-radius: 5px;
	margin-bottom: 20px;
}

.app-slideshow > div {
	display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
  height: 420px;
	border-radius: 0px;
}

.app-slideshow-item > div {
	display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;
	border-radius: 0px;
}

.footer-app-download { margin-top:10px }
.footer-app-download img {
	position: relative;
	left:-5px;
}

.loading-container span { margin-left: 5px; }
.loading-container {
	text-align: center;
	margin-top: 30px;
	color: grey
}

.spliter {
	border-top: #ddd 1px solid;
	height: 1px;
	margin: 7px 0px 7px 0px;
}
